import { createRouter, createWebHashHistory } from 'vue-router'
import Cookies from 'js-cookie'

const routes = [
    /* {
        path: '/',
        name: '首页',
        component: () => import('../views/ysgHome.vue'),
        redirect: '/index',
        children: [
            {
                path: 'index',
                name: '首页',
                mate: {
                    title: '首页',
                    icon: 'House'
                },
                component: () => import('../views/ysgIndex.vue')
            },
            {
                path:'mini',
                name:'小程序管理',
                mate: {
                    title: '小程序管理',
                    icon: 'Edit'
                },
                children:[
                    {path: '/manageinfo', name: '资讯管理', component: () => import('../views/manageInfo.vue')},
                    {path: '/manageimg', name: '轮播图管理', component: () => import('../views/manageTopimg.vue')},
                    {path: '/managenotice', name: '公告管理', component: () => import('../views/manageNotice.vue')},
                    {path: '/managespots', name: '景点管理', component: () => import('../views/manageSpots.vue')},
                    {path: '/managestrategy', name: '攻略管理', component: () => import('../views/manageStrategy.vue')},
                    {path: '/managecitycard', name: '城市名片管理', component: () => import('../views/manageCityCard.vue')},
                    {path: '/manageactivity', name: '活动管理', component: () => import('../views/manageActivity.vue')},
                    {path: '/managehotel', name: '酒店管理', component: () => import('../views/manageHotel.vue')},
                    {path: '/managerestaurant', name: '美食管理', component: () => import('../views/manageRestaurant.vue')},
                    {path: '/managejoy', name: '娱乐管理', component: () => import('../views/manageJoy.vue')},
                    {path: '/managetrip', name: '行程管理', component: () => import('../views/manageTrip.vue')},
                    {path: '/managenative', name: '特产管理', component: () => import('../views/manageNative.vue')},
                    {path: '/managegood', name: '商城管理', component: () => import('../views/manageGoods.vue')},
                    {path: '/wangedit', name: '富文本', component: () => import('../views/wangEdit.vue')},
                ]
            },
        ]
    }, */
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/ysgLogin.vue')
    },
    {
        path: '/InventoryAnalysis',
        name: 'InventoryAnalysis',
        component: () => import('../views/InventoryAnalysis.vue')
    },
    /* {
        path: '/UploadPdfToCode',
        name: 'UploadPdfToCode',
        component: () => import('../views/UploadPdfToCode.vue')
    } */

]

const router = createRouter({
    routes,
    history: createWebHashHistory()
})

// 跳转前验证用户是否登录
 router.beforeEach((to, from, next) => {
    if (to.path === '/login') {
        next()
    } else if (to.path === '/InventoryAnalysis') {
         next()
     } else {
        let token = localStorage.getItem('token')
        let username = Cookies.get('username')
        console.log(username)
        if (token === null || token === '' || username === null || username === '' || typeof username === 'undefined') {
            next({path: '/login'})
        } else {
            next()
        }
    }
})

export default router
